<template>
  <div>
    <b-button
      variant="success"
      @click="addDocModal"
      size="sm"
    >
      {{btnLabel}}
    </b-button>
    <modal-add-invoice
      id="addDocs"
      :entityType="entityClass"
      :entityId="entityId"
      :on-success="onAddSuccess"
      :document-entity-type="documentEntityType"
    />
  </div>
</template>

<script>
const ModalAddInvoice = () => import('@/components/documents/ModalAddInvoice.vue');

export default {
  name: 'NewInvoiceUpload',
  components: { ModalAddInvoice },
  props: {
    entityClass: String,
    entityId: String,
    documentEntityType: String,
    onSuccess: Function,
  },
  computed: {
    btnLabel() {
      if (this.entityClass === 'INVOICE') {
        return 'Carica una fattura';
      }
      return 'Aggiungi documento';
    },
  },
  methods: {
    addDocModal() {
      this.$bvModal.show('addDocs');
    },
    onAddSuccess(data) {
      if (this.onSuccess) {
        this.onSuccess(data);
      }
    },
  },
};
</script>

<style scoped>

</style>
